<template>
  <app-form :submit-method="openAatiConfirmationForm">
    <aati-beneficiary-details :beneficiary="beneficiary" />
    <app-panel is-outlined is-flat>
      <v-stepper vertical non-linear flat>
        <!-- Type arrêt travail -->
        <medical-leave-type-fields
          :type.sync="localFormData.medicalLeaveData.type"
          :prescriber-code.sync="localFormData.prescriberData.code"
          :prescriber-label.sync="localFormData.prescriberData.label"
          :atmp-indication="localFormData.medicalLeaveData.atmp || localFormData.medicalLeaveData.atmpPartial"
          :current-step.sync="currentStep"
        />

        <!-- Nature arrêt travail -->
        <medical-leave-nature-fields
          :value.sync="localFormData.medicalLeaveData.nature"
          :current-step.sync="currentStep"
        />

        <!-- Informations médicales -->
        <medical-leave-medical-infos-fields
          :value.sync="localFormData.medicalLeaveData"
          :beneficiary-nir="beneficiary.nir"
          :current-step.sync="currentStep"
        />

        <!-- Contexte -->
        <medical-leave-context-fields
          :value.sync="localFormData.backgroundData"
          :medical-data="localFormData.medicalLeaveData"
          :start-date="localFormData.durationData.startDate"
          :current-step.sync="currentStep"
        />

        <!-- Durée -->
        <medical-leave-duration-fields
          :value.sync="localFormData.durationData"
          :medical-data.sync="localFormData.medicalLeaveData"
          :background-data="localFormData.backgroundData"
          :current-step.sync="currentStep"
        />

        <!-- Situation professionnelle du patient -->
        <medical-leave-employer-fields :value.sync="localFormData.employerData" :current-step.sync="currentStep" />

        <!-- Adresse de visite -->
        <medical-leave-patient-visiting-address-fields
          :value.sync="localFormData.patientVisitingAddressData"
          :patient-address="patient.address"
          :vitale-card-address="vitaleCardAddress"
          :current-step.sync="currentStep"
        />

        <!-- Sorties et activités -->
        <medical-leave-outing-activity-fields
          v-if="localFormData.medicalLeaveData.nature !== 'TP'"
          :outing.sync="localFormData.outingData.outing"
          :outing-date.sync="localFormData.outingData.outingDate"
          :free-time.sync="localFormData.outingData.freeTime"
          :free-time-date.sync="localFormData.outingData.freeTimeDate"
          :motive-free-time.sync="localFormData.outingData.motiveFreeTime"
          :activity.sync="localFormData.activityData.activity"
          :activity-date.sync="localFormData.activityData.activityDate"
          :leave-start-date="localFormData.durationData.startDate"
          :leave-end-date="localFormData.durationData.endDate"
          :is-pregnancy-indication="localFormData.medicalLeaveData.pregnancy"
          :current-step.sync="currentStep"
        />
      </v-stepper>
    </app-panel>
  </app-form>
</template>

<script>
import AppForm from '@/components/ui/form/AppForm.vue'
import AppPanel from '@/components/ui/panel/AppPanel.vue'
import AatiBeneficiaryDetails from '@/modules/patient/components/sidebar/tlsi/aati/AatiBeneficiaryDetails'
import MedicalLeaveNatureFields from '@/modules/patient/components/sidebar/tlsi/aati/formSteps/MedicalLeaveNatureFields'
import MedicalLeaveMedicalInfosFields from '@/modules/patient/components/sidebar/tlsi/aati/formSteps/MedicalLeaveMedicalInfosFields'
import MedicalLeaveTypeFields from '@/modules/patient/components/sidebar/tlsi/aati/formSteps/MedicalLeaveTypeFields'
import MedicalLeaveContextFields from '@/modules/patient/components/sidebar/tlsi/aati/formSteps/MedicalLeaveContextFields'
import MedicalLeaveDurationFields from '@/modules/patient/components/sidebar/tlsi/aati/formSteps/MedicalLeaveDurationFields'
import MedicalLeaveEmployerFields from '@/modules/patient/components/sidebar/tlsi/aati/formSteps/MedicalLeaveEmployerFields'
import MedicalLeavePatientVisitingAddressFields from '@/modules/patient/components/sidebar/tlsi/aati/formSteps/MedicalLeavePatientVisitingAddressFields'
import MedicalLeaveOutingActivityFields from '@/modules/patient/components/sidebar/tlsi/aati/formSteps/MedicalLeaveOutingActivityFields.vue'

import Patient from '@/modules/patient/models/Patient'

export default {
  name: 'AatiForm',
  components: {
    AppForm,
    AppPanel,
    AatiBeneficiaryDetails,
    MedicalLeaveNatureFields,
    MedicalLeaveMedicalInfosFields,
    MedicalLeaveTypeFields,
    MedicalLeaveContextFields,
    MedicalLeaveDurationFields,
    MedicalLeaveEmployerFields,
    MedicalLeavePatientVisitingAddressFields,
    MedicalLeaveOutingActivityFields,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    patient: {
      type: Patient,
      required: true,
    },
    beneficiary: {
      type: Patient,
      required: true,
    },
    dataSource: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      currentStep: 1,
      localFormData: null,
    }
  },
  computed: {
    vitaleCardAddress () {
      return this.dataSource === 'CV' ? this.beneficiary.address : null
    },
  },
  watch: {
    formData: {
      immediate: true,
      handler () {
        this.localFormData = this.formData
      },
    },
  },
  methods: {
    async openAatiConfirmationForm () {
      return this.localFormData
    },
  },
}
</script>
<style lang="scss" scoped>
.aati-form__stepper {
  box-shadow: none !important;
}
::v-deep {
  .app-autocomplete {
    position: relative;
  }

  .app-autocomplete .v-autocomplete__content {
    // Permet au menu de se positionner de manière fixe au dessus
    position: fixed !important;
    top: auto !important;
    left: auto !important;
    margin-top: map-get($spacers, 8);
    max-height: 300px;
  }
}
</style>