import Vue from 'vue'
import { getFromAPI, postToAPI, putToAPI } from '@/services/api.js'
import { getUUIDFromIRI } from '@/utils/functions/getUUIDFromIRI'

import Measure from '../models/Measure'
import { deleteFromAPI } from '@/services/api.js'

export const state = () => ({
  measures: {},
  hasLoadedMeasures: {},
  isLoadingMeasures: false,
})

export const getters = {
  getMeasuresFromPatientByName: state => (patient, measureName, orderAsc = true) => {
    const measures = state.measures?.[patient.getIri()]?.[measureName]

    if (! measures) {
      return null
    }

    return Object.values(measures)
      .sort((a, b) => {
        const aTime = new Date(a.measuredAt).getTime()
        const bTime = new Date(b.measuredAt).getTime()
        if (aTime === bTime) {
          const aCreatedTime = new Date(a.createdAt).getTime()
          const bCreatedTime = new Date(b.createdAt).getTime()
          return orderAsc ? aCreatedTime - bCreatedTime : bCreatedTime - aCreatedTime
        }
        return orderAsc ? aTime - bTime : bTime - aTime
      })
  },
  getPatientLastMeasure: (state, getters) => (patient, measureName) => {
    const measures = getters.getMeasuresFromPatientByName(patient, measureName, false)
    if (! measures) {
      return null
    }

    return measures[0]
  },
  getPatientPreviousMeasure: (state, getters) => (patient, measureName, consultation) => {
    const measures = getters.getMeasuresFromPatientByName(patient, measureName, false)?.
      filter((m) => m.consultation === null || m.consultation !== consultation['@id'])
    if (! measures) {
      return null
    }
    const consultationDate = new Date(consultation.date).getTime()
    const consultationDateCreated = new Date(consultation.createdAt).getTime()
    const previousMeasures = measures.filter((mes) => {
      const measuredAt = new Date(mes.measuredAt).getTime()
      if (consultationDate === measuredAt) {
        return new Date(mes.consultationCreatedAt ?? mes.createdAt).getTime() < consultationDateCreated
      }
      return measuredAt < consultationDate
    })
    if (! previousMeasures) {
      return null
    }
    return previousMeasures[0] ?? null
  },
  getHasLoadedMeasuresByPatientUuid: state => patientUuid => state.hasLoadedMeasures[patientUuid],
  isLoadingMeasures: state => state.isLoadingMeasures,
}

export const mutations = {
  SET_MEASURE (state, measureDefinition) {
    const measure = new Measure(measureDefinition)
    const patientIri = measure.patient

    if (state.measures[patientIri] === undefined) {
      Vue.set(state.measures, patientIri, {})
    }

    if (state.measures[patientIri][measure.name] === undefined) {
      Vue.set(state.measures[patientIri], measure.name, {})
    }

    Vue.set(state.measures[patientIri][measure.name], measure.getIri(), measure)
  },
  UPDATE_MEASURE (state, measureData) {
    const measure = new Measure(measureData)
    const patientIri = measure.patient
    Vue.set(state.measures[patientIri][measure.name], measure.getIri(), measure)
  },
  RESET_MEASURE (state, patientIri) {
    Vue.delete(state.measures, [patientIri])
  },
  SET_HAS_LOADED_MEASURES (state, { patientUuid, hasLoadedMeasures }) {
    Vue.set(state.hasLoadedMeasures, patientUuid, hasLoadedMeasures)
  },
  SET_IS_LOADING_MEASURES (state, isLoadingMeasures) {
    state.isLoadingMeasures = isLoadingMeasures
  },
}

export const actions = {
  async fetchPatientMeasures ({ state, commit }, patientIRI) {
    commit('RESET_MEASURE', patientIRI)
    commit('SET_IS_LOADING_MEASURES', true)
    const { data } = await getFromAPI(patientIRI + '/measures')
    data['hydra:member'].forEach(tag => {
      commit('SET_MEASURE', tag)
    })
    commit('SET_IS_LOADING_MEASURES', false)
    const patientUuid = getUUIDFromIRI(patientIRI)
    if (! state.hasLoadedMeasures[patientUuid]) {
      commit('SET_HAS_LOADED_MEASURES', {
        patientUuid,
        hasLoadedMeasures: true,
      })
    }
  },
  async fetchPatientIndicators ({ commit }, patientIRI) {
    const { data } = await getFromAPI(patientIRI + '/indicators')
    data['hydra:member'].forEach(tag => commit('SET_MEASURE', tag))
  },
  async insertPatientMeasure ({ commit, dispatch }, { patientMeasure, fetchIndicators }) {
    const { data } = await postToAPI('/api/patient_measures', patientMeasure)
    if (fetchIndicators) {
      await dispatch('fetchPatientIndicators', patientMeasure.patient)
    }
    commit('SET_MEASURE', data)
    return data
  },
  async updatePatientMeasure ({ commit, dispatch }, { patientMeasure, fetchIndicators }) {
    const { data } = await putToAPI(`${patientMeasure['@id']}`, { data: patientMeasure })
    if (fetchIndicators) {
      await dispatch('fetchPatientIndicators', patientMeasure.patient)
    }
    commit('UPDATE_MEASURE', data)
  },
  async deletePatientMeasure ({ dispatch }, { patientMeasure, fetchIndicators }) {
    await deleteFromAPI(patientMeasure['@id'])
    if (fetchIndicators) {
      await dispatch('fetchPatientMeasures', patientMeasure.patient)
      await dispatch('fetchPatientIndicators', patientMeasure.patient)
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}