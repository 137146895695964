<template>
  <validation-provider ref="validator" slim>
    <v-scroll-x-transition mode="out-in">
      <section v-if="getHasVitaleCard">
        <slot />
      </section>
      <div v-else v-test="'no-vitale-illustration'" class="fill-height d-flex align-center justify-center">
        <div class="flex-list-4 flex-column align-center justify-center">
          <svg
            v-test="'no-vitale-card'"
            height="300"
            width="100%"
            class="icon-no-vitale-card"
          >
            <use xlink:href="#icon-no-vitale-card" />
          </svg>
          <div class="flex-list-1 flex-column align-center text-center">
            <div v-if="isVitaleLoading">
              <p class="subtitle-1">
                Lecture de la carte
              </p>
              <p class="secondary--text">
                Veuillez patienter...
              </p>
            </div>
            <div v-else>
              <p class="subtitle-1">
                {{ unfoundedCardMessage }}
              </p>
              <p class="secondary--text">
                Veuillez l'introduire afin de pouvoir continuer
              </p>
              <p class="secondary--text mt-4">
                <n-button
                  label="Relancer la lecture si une carte est déjà insérée"
                  bg-color="secondary lighten-4"
                  size="small"
                  outlined
                  @click="forceReading"
                />
              </p>
            </div>
          </div>
          <n-button
            v-if="canUseApcv && !isVitaleLoading"
            bg-color="primary"
            icon="card"
            label="Continuer avec ApCV"
            @click="useApCV"
          />
        </div>
      </div>
    </v-scroll-x-transition>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

import { mapGetters, mapMutations } from 'vuex'
import iCanopeeCardReaderInstance from '@/services/vendors/icanopee/classes/ICanopeeCardReader'

import { CARD_TYPES } from '@/services/card-reader/constants/cardTypes'

export default {
  name: 'VitaleCardSwitchViewValidator',
  components: { ValidationProvider },
  props: {
    unfoundedCardMessage: {
      type: String,
      default: 'Carte vitale absente',
    },
    canUseApcv: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('cardReaders', ['getHasVitaleCard', 'getIsLoadingCard', 'getCardContent']),
    isVitaleLoading () {
      return this.getIsLoadingCard(CARD_TYPES.VITALE)
    },
  },
  watch: {
    getHasVitaleCard (hasVitale) {
      if (! hasVitale) {
        this.$emit('remove-card')
        this.validate()
        return
      }
      // Valide le formulaire a la fin de la transition
      setTimeout(() => {
        this.$emit('card-read')
        this.validate()
      }, 350)
    },
  },
  async mounted () {
    // Permet d'assurer une validation sur des composants avec des montages dynamique tels que des modales
    requestAnimationFrame(this.validate)
    if (! this.isVitaleLoading && this.getCardContent(CARD_TYPES.VITALE) === null) {
      await this.forceReading()
    }
  },
  methods: {
    ...mapMutations('cardReaders', ['SET_CARD_CONTENT']),
    validate () {
      this.$refs.validator.applyResult({
        valid: this.getHasVitaleCard,
        errors: this.getHasVitaleCard ? [] : [this.unfoundedCardMessage],
      })
    },
    useApCV () {
      this.$emit('use-apcv')
    },
    async forceReading () {
      const cardReaderType = CARD_TYPES.VITALE
      this.SET_CARD_CONTENT({
        cardReaderType,
        content: null,
      })
      const content = await iCanopeeCardReaderInstance.fetchVitaleCardContent()
      this.SET_CARD_CONTENT({
        cardReaderType,
        content,
      })
    },
  },
}
</script>