export const MEDICAL_LEAVE_INDICATION_DATA = Object.freeze([
  {
    label: 'une affection de longue durée',
    regulatoryText: 'Afin de permettre l\'indemnisation des arrêts de travail conformément à la réglementation et dans le souci d\'un meilleur service rendu à l\'assuré(e), il vous est demandé de bien vouloir indiquer si l\'arrêt est en rapport avec une affectation visée aux articles L324-0 du Code de la Sécurité Sociale, à savoir : \n- affection nécessitant une interruption de travail ou de soins continus supérieurs à 6 mois non exonérante, \n- affection de longue duréee exonérante reconnue sur list (ALD 30) ou hors liste. Dans le cadre d\'un arrêt en lien avec une ALD, le délai de carence ne s\'applique pas.',
    fullTime: {
      name: 'ald',
      natureAvailability: ['TC', 'TCP'],
    },
    partialTime: {
      name: 'aldPartial',
      natureAvailability: ['TP', 'TCP'],
    },
  },
  {
    label: 'un état pathologique lié à une grossesse',
    regulatoryText: 'Un état pathologique résultant de la grossesse permet l\'indemnisation d\'une période supplémentaire au titre de l\'assurance maternité.',
    fullTime: {
      name: 'pregnancy',
      natureAvailability: ['TC'],
    },
    partialTime: {
      name: '',
      natureAvailability: [],
    },
  },
  {
    label: 'un accident de travail ou maladie professionnelle',
    regulatoryText: 'Afin de permettre l\'indemnisation des arrêts de travail conformément à la réglementation et dans le souci d\'un meilleur service rendu à l\'assuré(e), il vous est demandé de bien vouloir indiquer si l\'arrêt de travail est en rapport avec un accident de travail ou une maladie professionnelle reconnue ou non. Dans ce cas, vous devrez indiquer : \n- pour un accident du travail : la date présumée de l\'accident \n- pour une maladie professionnelle : la date déclarée de la première constatation médicale. C\'est la date à laquelle les symptômes ou les lésions révélant la maladie ont été constatés pour la première fois par le médecin même si le diagnostic n\'a été établi que postérieurement.',
    fullTime: {
      name: 'atmp',
      natureAvailability: ['TC', 'TCP'],
    },
    partialTime: {
      name: 'atmpPartial',
      natureAvailability: ['TP', 'TCP'],
    },
  },
  {
    label: 'le décès d\'un enfant (ou d\'une personne à charge de moins de 25 ans)',
    regulatoryText: 'En application de l\'article L.321-1-1 du code de la sécurité sociale, en cas de décès d\'un enfant ou d\'une personne à charge de l\'assuré, avant l\'âge de 25 ans, le premier arrêt de travail prescrit pour incapacité de continuer ou de reprendre le travail dans les 13 semaines qui suivent la date du décès est exonéré du délai de carence appliqué pour le paiement des indemnités journalières de l\'assurance maladie.',
    fullTime: {
      name: 'childDeath',
      natureAvailability: ['TC'],
    },
    partialTime: {
      name: '',
      natureAvailability: [],
    },
  },
])