<template>
  <validation-observer v-slot="{ valid }" slim>
    <aati-form-step
      class="medical-leave-outing-activity-fields"
      :step="step"
      :current-step="currentStep"
      :is-form-valid="valid"
      title="Autorisations de sorties et d'activités"
      @update-current-step="$emit('update:current-step', $event)"
    >
      <div class="pb-2">
        <div class="medical-leave-outing-fields__main__limited-time mb-2">
          <app-checkbox
            v-test="'outing-input'"
            :input-value="outing"
            label="Sorties autorisées, à partir du"
            :ripple="false"
            @change="handleOutingInput"
          />
          <app-date-picker
            ref="outing-date-input"
            v-test="'outing-date-input'"
            :value="outingDate"
            :show-label="false"
            :disabled="!outing"
            :min="outingAndActivityDatesMin"
            :max="allDatesMax"
            :rules="{ 'required': outing, afterOrEqualMinDate: { minDate: outingAndActivityDatesMin, minDateStr: 'début d\'arrêt' }, beforeOrEqualEndDate: { endDate: leaveEndDate } }"
            @input="handleOutingDateInput"
          />
          <app-icon icon="info" color="secondary lighten-1" :tooltip="limitedTimeOutingText" />
        </div>
        <div v-if="outing" class="flex-grow-1 pl-5 mb-3">
          <div>
            <div class="medical-leave-outing-fields__main__free-time">
              <app-checkbox
                v-test="'free-time-input'"
                :input-value="freeTime"
                :label="freeTimeOutingLabel"
                :ripple="false"
                @change="handleFreeTimeInput"
              />
              <app-date-picker
                v-if="freeTime"
                ref="free-time-date-input"
                v-test="'free-time-date-input'"
                :value="freeTimeDate"
                :show-label="false"
                :min="freeTimeDateMin"
                :max="allDatesMax"
                :rules="{ 'required': true, afterOrEqualMinDate: { minDate: freeTimeDateMin, minDateStr: 'début d\'autorisation des sorties' }, beforeOrEqualEndDate: { endDate: leaveEndDate } }"
                @input="handleFreeTimeDateInput"
              />
              <app-icon
                v-if="freeTime"
                icon="info"
                color="secondary lighten-1"
                :tooltip="freeTimeOutingText"
              />
            </div>
            <div v-if="freeTime" class="medical-leave-outing-fields__main__free-time-motive">
              <app-text-field
                v-test="'motive-free-time-input'"
                class="pl-6 pt-3"
                :value="motiveFreeTime"
                rules="required|max:60"
                label="Motif"
                @input="handleMotiveFreeTimeInput"
              />
              <app-icon icon="info" color="secondary lighten-1" :tooltip="motiveFreeTimeOutingText" />
            </div>
          </div>
        </div>
      </div>
      <div class="medical-leave-activity-fields__main pb-2">
        <app-checkbox
          v-test="'activity-input'"
          :input-value="activity"
          label="Activités autorisées, à partir du"
          :ripple="false"
          @change="handleActivityInput"
        />
        <app-date-picker
          ref="activity-date-input"
          v-test="'activity-date-input'"
          :value="activityDate"
          :show-label="false"
          :hide-hint="true"
          :disabled="!activity"
          :min="outingAndActivityDatesMin"
          :max="allDatesMax"
          :rules="{ 'required': activity, afterOrEqualMinDate: { minDate: outingAndActivityDatesMin, minDateStr: 'début d\'arrêt' }, beforeOrEqualEndDate: { endDate: leaveEndDate } }"
          @input="handleActivityDateInput"
        />
        <app-icon icon="info" color="secondary lighten-1" :tooltip="activityText" />
      </div>
    </aati-form-step>
  </validation-observer>
</template>

<script>
import AatiFormStep from '@/modules/patient/components/sidebar/tlsi/aati/AatiFormStep.vue'
import { ValidationObserver, extend } from 'vee-validate'
import AppCheckbox from '@/components/ui/form/AppCheckbox.vue'
import AppDatePicker from '@/components/ui/form/AppDatePicker.vue'
import AppTextField from '@/components/ui/form/AppTextField.vue'

export default {
  name: 'MedicalLeaveOutingActivityFields',
  components: {
    AppTextField,
    AppDatePicker,
    AppCheckbox,
    AatiFormStep,
    ValidationObserver,
  },
  props: {
    outing: {
      type: Boolean,
      default: false,
    },
    outingDate: {
      type: String,
      default: null,
    },
    freeTime: {
      type: Boolean,
      default: false,
    },
    freeTimeDate: {
      type: String,
      default: null,
    },
    motiveFreeTime: {
      type: String,
      default: null,
    },
    activity: {
      type: Boolean,
      default: false,
    },
    activityDate: {
      type: String,
      default: null,
    },
    leaveStartDate: {
      type: String,
      default: null,
    },
    leaveEndDate: {
      type: String,
      default: null,
    },
    isPregnancyIndication: {
      type: Boolean,
      default: false,
    },
    currentStep: {
      type: Number,
      default: 1,
    },
  },
  data () {
    return {
      step: 8,
      limitedTimeOutingText: 'Sorties autorisées avec horaires limités\n\nSi l\'état du patient permet des sorties, alors le patient doit respecter les heures de présence à son domicile de 9 à 11 heures et de 14 à 16 heures, sauf en cas de soins ou d\'examens médicaux.',
      freeTimeOutingText: 'Sorties autorisées avec horaires libres\n\nLe patient n\'a pas à respecter les heures de présence à domicile. La raison de cette dispense exceptionnelle doit être précisée.',
      motiveFreeTimeOutingText: 'L\'article L.162-4-1, 1er alinéa du Code de la sécurité sociale prévoit la mention des éléments d\'ordre médical justifiant l\'arrêt de travail en précisant, si besoin, les éléments justifiant les sorties sans restriction d\'horaire.',
      activityText: 'L\'assuré ne peut exercer une activité durant son arrêt de travail et conserver le bénéfice de ses indemnités journalières que s\'il dispose d\'une autorisation expresse du praticien qui a prescrit l\'arrêt de travail.\nSi nécessaire, vous devez autoriser l\'exercice d\'activités en dehors du domicile en précisant la date de début d\'activité.',
    }
  },
  computed: {
    outingAndActivityDatesMin () {
      return this.leaveStartDate ? new Date(this.leaveStartDate) : new Date()
    },
    freeTimeOutingLabel () {
      return `Sans restriction${this.freeTime ? ', à partir du' : ''}`
    },
    freeTimeDateMin () {
      return this.outingDate ? new Date(this.outingDate) : this.leaveStartDate ? new Date(this.leaveStartDate) : new Date()
    },
    allDatesMax () {
      return this.leaveEndDate ? new Date(this.leaveEndDate) : null
    },
  },
  watch: {
    isPregnancyIndication: {
      immediate: true,
      handler () {
        this.handlePregnancyIndication()
      },
    },
    leaveStartDate: {
      handler () {
        this.handlePregnancyIndication()
      },
    },
  },
  methods: {
    handleOutingInput (outing) {
      this.$emit('update:outing', outing)
      if (outing === false) {
        this.handleOutingDateInput(null)
        this.handleFreeTimeInput(false)
      } else {
        this.handleOutingDateInput(this.leaveStartDate)
      }
    },
    handleOutingDateInput (outingDate) {
      this.$emit('update:outing-date', outingDate)
    },
    handleFreeTimeInput (freeTime) {
      this.$emit('update:free-time', freeTime)
      if (freeTime === false) {
        this.handleFreeTimeDateInput(null)
        this.handleMotiveFreeTimeInput(null)
      } else {
        this.handleFreeTimeDateInput(this.outingDate)
        this.$nextTick(() => {
          this.$refs['free-time-date-input']?.$refs.input.validate()
        })
      }
    },
    handleFreeTimeDateInput (freeTimeDate) {
      this.$emit('update:free-time-date', freeTimeDate)
    },
    handleMotiveFreeTimeInput (motiveFreeTime) {
      this.$emit('update:motive-free-time', motiveFreeTime)
    },
    handleActivityInput (activity) {
      this.$emit('update:activity', activity)
      if (activity === false) {
        this.handleActivityDateInput(null)
      } else {
        this.handleActivityDateInput(this.leaveStartDate)
      }
    },
    handleActivityDateInput (activityDate) {
      this.$emit('update:activity-date', activityDate)
    },
    handlePregnancyIndication () {
      if (this.isPregnancyIndication) {
        this.handleOutingInput(true)
        this.handleOutingDateInput(this.leaveStartDate)
        this.handleFreeTimeInput(true)
        this.handleFreeTimeDateInput(this.leaveStartDate)
        this.handleMotiveFreeTimeInput('Congé pathologique résultant de la grossesse')
      } else {
        this.handleOutingInput(false)
        this.handleFreeTimeInput(false)
      }
    },
  },
}
extend('afterOrEqualMinDate', {
  validate (value, { minDate, minDateStr }) {
    if (minDate) {
      const valueDateString = value.split('/').reverse().join('-')
      const formattedValue = new Date(valueDateString)
      if (formattedValue < minDate) {
        return `La date doit être postérieure ou égale à la date de ${minDateStr}`
      }
    }
    return true
  },
  params: ['minDate', 'minDateStr'],
})
extend('beforeOrEqualEndDate', {
  validate (value, { endDate }) {
    if (endDate) {
      const valueDateString = value.split('/').reverse().join('-')
      const formattedValue = new Date(valueDateString)
      const formattedEndDate = new Date(endDate)
      if (formattedValue > formattedEndDate) {
        return 'La date doit être antérieure ou égale à la date de fin d\'arrêt'
      }
    }
    return true
  },
  params: ['endDate'],
})
</script>
<style lang="scss" scoped>
.medical-leave-outing-fields {
  &__main,
  &__main__limited-time,
  &__main__free-time,
  &__main__free-time-motive {
    display: flex;
    align-items: baseline;
  }

  &__main {
    gap: map-get($spacers, 4);

    &__limited-time,
    &__free-time,
    &__free-time-motive {
      gap: map-get($spacers, 2);
    }

    &__limited-time,
    &__free-time {
      ::v-deep {
        .app-checkbox__label {
          color: var(--v-text-base) !important;
        }

        .app-date-picker__field {
          padding-top: 0;
          max-width: 150px;
        }
      }
    }
  }
}
.medical-leave-activity-fields {
  &__main {
    display: flex;
    align-items: baseline;
    gap: map-get($spacers, 2);

    ::v-deep {
      .app-checkbox__label {
        color: var(--v-text-base) !important;
      }

      .app-date-picker__field {
        padding-top: 0;
        max-width: 150px;
      }
    }
  }
}
</style>