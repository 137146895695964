import Vue from 'vue'
import { getFromAPI, putToAPI } from '@/services/api.js'
import { getUUIDFromIRI } from '@/utils/functions/getUUIDFromIRI'

import PatientFemaleMedicalDataset from '../components/patientFile/femaleMedicalDataset/models/PatientFemaleMedicalDataset'

export const state = () => ({
  femaleMedicalDataset: {},
  hasLoadedFemaleMedicalDataset: {},
  isLoadingFemaleMedicalDataset: false,
})

export const getters = {
  getFemaleMedicalDataset: state => (patientUuid) => {
    return state.femaleMedicalDataset[patientUuid]
  },
  getHasLoadedFemaleMedicalDatasetByPatientUuid: state => patientUuid => state.hasLoadedFemaleMedicalDataset[patientUuid],
  isLoadingFemaleMedicalDataset: state => state.isLoadingFemaleMedicalDataset,
}

export const mutations = {
  SET_FEMALE_MEDICAL_DATASET (state, { patientUuid, femaleMedicalDataset }) {
    Vue.set(state.femaleMedicalDataset, patientUuid, femaleMedicalDataset)
  },
  SET_HAS_LOADED_FEMALE_MEDICAL_DATASET (state, { patientUuid, hasLoadedFemaleMedicalDataset }) {
    Vue.set(state.hasLoadedFemaleMedicalDataset, patientUuid, hasLoadedFemaleMedicalDataset)
  },
  SET_IS_LOADING_FEMALE_MEDICAL_DATASET (state, isLoadingFemaleMedicalDataset) {
    state.isLoadingFemaleMedicalDataset = isLoadingFemaleMedicalDataset
  },
}

export const actions = {
  async fetchFemaleMedicalDataset ({ state, commit }, patient) {
    commit('SET_IS_LOADING_FEMALE_MEDICAL_DATASET', true)
    const { data } = await getFromAPI(patient.femaleMedicalDataset)
    const patientUuid = getUUIDFromIRI(data.patient)
    commit('SET_FEMALE_MEDICAL_DATASET', {
      patientUuid,
      femaleMedicalDataset: new PatientFemaleMedicalDataset(data),
    })
    commit('SET_IS_LOADING_FEMALE_MEDICAL_DATASET', false)
    if (! state.hasLoadedFemaleMedicalDataset[patientUuid]) {
      commit('SET_HAS_LOADED_FEMALE_MEDICAL_DATASET', {
        patientUuid,
        hasLoadedFemaleMedicalDataset: true,
      })
    }
  },
  async updateFemaleMedicalDataset ({ commit }, { femaleMedicalDataset }) {
    const { data } = await putToAPI(femaleMedicalDataset.getIri(), { data: femaleMedicalDataset })
    commit('SET_FEMALE_MEDICAL_DATASET', {
      patientUuid: getUUIDFromIRI(data.patient),
      femaleMedicalDataset: new PatientFemaleMedicalDataset(data),
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}