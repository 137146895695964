<template>
  <div>
    <app-actions-menu
      :actions="actions"
      :max-actions-visible="1"
      menu-width="300"
      @update:is-open="handleOpenMenu"
    />
    <patient-logs-modal :is-open.sync="isPatientLogsModalOpen" :patient="patient" />
    <tlsi-modals :patient="patient" :active-tlsi.sync="activeTlsi" />
    <aldi-result-summary-modal :response-payload="aldiResult" />
  </div>
</template>

<script>
import AppActionsMenu from '@/components/ui/actionsMenu/AppActionsMenu.vue'
import PatientLogsModal from '@/modules/patient/components/PatientLogsModal.vue'
import TlsiModals from '@/modules/patient/components/sidebar/tlsi/TlsiModals.vue'
import AldiResultSummaryModal from '@/modules/patient/components/sidebar/tlsi/aldi/AldiResultSummaryModal'
import NovaTools from '@/nova-tools/NovaTools'

import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem'
import Tlsi from '@/modules/patient/components/sidebar/tlsi/models/Tlsi'
import Aldi from '@/modules/patient/components/sidebar/tlsi/aldi/models/Aldi'
import Patient from '@/modules/patient/models/Patient'

import { mapActions, mapGetters } from 'vuex'
import { ROUTE_NAMES, TLSI_TYPES } from '@/modules/patient/constants'
import { CARD_TYPES } from '@/services/card-reader/constants/cardTypes'

import { softInclude } from '@/utils/functions/words'
import { getEnv } from '@/utils/functions/env.js'

const READONLY_ACTION_TOOLTIP_MESSAGE = 'Indisponible à cette étape'
const DMP_ACTION_DISABLED_TOOLTIP_MESSAGE = 'Accès au DMP non autorisé en l\'absence du matricule INS du patient'

export default {
  name: 'PatientSidebarActions',
  components: {
    AppActionsMenu,
    PatientLogsModal,
    TlsiModals,
    AldiResultSummaryModal,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      isPatientLogsModalOpen: false,
      activeTlsi: null,
      isPatientDeletable: false,
      aldiResult: {
        report: null,
        beneficiary: null,
        aldList: [],
      },
    }
  },
  computed: {
    ...mapGetters('cardReaders', ['getHasCpxCard', 'getHasVitaleCard', 'getCardContent']),
    ...mapGetters('auth', ['getCurrentUser', 'isCurrentUserPractitioner']),
    ...mapGetters('patient', ['getSettings']),
    isTlsiAccessible () {
      return getEnv('VUE_APP_TLSI_ACTIVATION') === 'true'
    },
    dmpAllowed () {
      return this.patient.insIdentity.isValidated()
    },
    aatiDisallowed () {
      return this.patient.getAge({ returnAsRawInterval: true }).years < 16 || this.patient.billDataset?.quality !== '0'
    },
    aatiDisallowedMessage () {
      if (this.patient.getAge({ returnAsRawInterval: true }).years < 16 && this.patient.billDataset?.quality !== '0') {
        return 'Le téléservice AATi n\'est disponible que pour les patients de plus de 16 ans dont la qualité du bénéficiaire est « assuré » (donnée à vérifier dans « organismes de rattachement » du dossier administratif).'
      } else if (this.patient.billDataset?.quality !== '0') {
        return 'Le téléservice AATi n\'est pas disponible pour les patients dont la qualité du bénéficiaire n\'est pas « assuré » (donnée à vérifier dans « organismes de rattachement » du dossier administratif).'
      }
      return 'Le téléservice AATi n\'est pas disponible pour les patients de moins de 16 ans.'
    },
    actions () {
      const disabledPatientEdit = [
        'createPrescription',
        'editPrescription',
      ].includes(this.$router.currentRoute.name)

      const actions = [
        [
          new ActionMenuItem('edit', 'Modifier le dossier administratif', this.goToPatientEdit, {
            disabled: disabledPatientEdit,
            disabledTooltip: disabledPatientEdit ? READONLY_ACTION_TOOLTIP_MESSAGE : null,
          }),
        ],
        [
          this.tlsiAction,
          new ActionMenuItem('dmp', 'Accéder au DMP', () => {
            NovaTools.icanopee.efficience.openModalIframe(this.patient)
          }, {
            disabled: ! this.dmpAllowed,
            disabledTooltip: this.dmpAllowed ? null : DMP_ACTION_DISABLED_TOOLTIP_MESSAGE,
          }),
        ],
        [
          new ActionMenuItem('archive', this.patient.archived ? 'Restaurer' : 'Archiver', this.togglePatientArchivedStatus),
          new ActionMenuItem('delete', 'Supprimer', this.deletePatient, {
            disabled: ! this.isPatientDeletable,
            disabledTooltip: 'Ce patient ne peut pas être supprimé, il possède des données médicales ou des documents',
            important: true,
          }),
        ],
      ]
      if (this.getCurrentUser.isAdmin) {
        actions[1].push(new ActionMenuItem('logs', 'Journal des traces', () => this.isPatientLogsModalOpen = true))
      }
      return actions
    },
    tlsiAction () {
      const isTlsiDisabled = ! this.isCurrentUserPractitioner || ! this.getHasCpxCard || ! this.isTlsiAccessible

      let tlsiDisabledMessage = this.getHasCpxCard ? null : 'Nécessite une carte CPS'
      tlsiDisabledMessage = this.isTlsiAccessible ? tlsiDisabledMessage : 'Bientôt disponibles'

      return new ActionMenuItem('teleservices', 'Téléservices', null, {
        disabled: isTlsiDisabled,
        subtitle: tlsiDisabledMessage,
        children: [
          this.getTlsiAction(TLSI_TYPES.DMTI, { subtitle: 'Se déclarer médecin traitant du patient' }),
          this.getTlsiAction(TLSI_TYPES.IMTI, { subtitle: 'Obtenir le médecin traitant' }),
          this.getTlsiAction(TLSI_TYPES.ALDI, { subtitle: 'Rechercher les ALD du patient' }),
          // this.getTlsiAction(TLSI_TYPES.AATI, {
          //   subtitle: 'Déclarer un arrêt de travail',
          //   disabled: this.aatiDisallowed,
          //   disabledTooltip: this.aatiDisallowedMessage,
          // }),
        ],
      })
    },
    autoCallAldi () {
      return this.getSettings?.aldiAutomaticCallSetting
    },
  },
  watch: {
    getHasVitaleCard: {
      immediate: true,
      handler () {
        this.handleAldiAutomatism()
      },
    },
  },
  async created () {
    await this.fetchSettings()
  },
  methods: {
    ...mapActions('patient', ['fetchSettings']),
    async handleOpenMenu (isOpen) {
      if (isOpen) {
        this.isPatientDeletable = await Patient.isDeletable(this.patient.getUuid())
      }
    },
    async togglePatientArchivedStatus () {
      if (this.patient.archived) {
        await this.setPatientArchiveStatus(false)
        NovaTools.notify.success(`Le patient ${this.patient.getCivilState()} a été restauré avec succès`)
        return
      }
      await this.archivePatient()
    },
    async setPatientArchiveStatus (archiveStatus) {
      await Patient.update({
        ...this.patient,
        archived: archiveStatus,
      })
    },
    archivePatient () {
      NovaTools.dialog.confirm(
        'Archiver le patient',
        `Êtes-vous sûr(e) de vouloir archiver le patient ${this.patient.getCivilState()} ?
        La prise de rendez-vous ainsi que les alertes VSM seront désactivées pour ce patient`,
        async () => {
          await this.setPatientArchiveStatus(true)
          NovaTools.notify.success(`Le patient ${this.patient.getCivilState()} a été archivé avec succès`)
        })
    },
    deletePatient () {
      NovaTools.dialog.confirm(
        'Supprimer le patient',
        `Êtes-vous sûr(e) de vouloir supprimer le patient ${this.patient.getCivilState()} ?`,
        async () => {
          await Patient.delete(this.patient.getUuid())
          NovaTools.notify.success(`Le patient ${this.patient.getCivilState()} a été supprimé avec succès`)
          this.$router.push({ name: ROUTE_NAMES.LISTING })
        })
    },
    getTlsiAction (tlsi, options) {
      return new ActionMenuItem(null, tlsi.label, () => this.activeTlsi = tlsi.value, options)
    },
    goToPatientEdit () {
      this.$router.push({
        name: ROUTE_NAMES.PATIENT_EDIT,
        params: { uuid: this.patient.getUuid() },
      })
    },
    async handleAldiAutomatism () {
      if (this.isTlsiAccessible && this.autoCallAldi && this.getHasCpxCard && this.getHasVitaleCard) {
        const insureds = this.getCardContent(CARD_TYPES.VITALE) || []
        const correspondingBeneficiaryIndex = insureds.findIndex(insured => softInclude(insured.patient.getCivilState(), this.patient.getCivilState()) && (insured.patient.birthDate === this.patient.birthDate))
        if (correspondingBeneficiaryIndex > - 1) {
          const notificationId = NovaTools.notify.info('Récupération des ALD en cours...', { timeout: - 1 })
          try {
            const aldiInstance = new Aldi({ patient: this.patient })
            const requestParams = {
              patientData: null,
              template: null,
            }
            requestParams.template = await aldiInstance.getAldiTemplate(correspondingBeneficiaryIndex, false)
            requestParams.patientData = await Tlsi.getBeneficiaryData(correspondingBeneficiaryIndex, 'PatientData')
            const result = aldiInstance.sendAldiRequest(requestParams)

            this.aldiResult = await aldiInstance.formatAldiResult(result, this.patient.getCivilState())
          } catch (error) {
            if (typeof error === 'object' && error.response && error.response.data) {
              NovaTools.notify.error(error.response.data['hydra:detail'], { title: error.response.data['hydra:description'] })
            } else {
              NovaTools.notify.error(error)
            }
          } finally {
            NovaTools.notify.remove(notificationId)
          }
        }
      }
    },
  },
}
</script>