<template>
  <div>
    <patient-sidebar-panel-listing
      title="Prescrit depuis le LAP"
      :items="ongoingInternalMedications"
      :loading="isLoadingOngoingMedications"
      no-data-text="Aucun traitement en cours"
      :item-model-class="Medication"
      :patient="patient"
    >
      <template #item="{ item }">
        <ongoing-medication-listing-item :item="item" />
      </template>
    </patient-sidebar-panel-listing>
    <n-divider :spacing="0" />
    <patient-sidebar-panel-listing
      v-test="'ongoing-external-medications-listing'"
      title="Prescrit par un confrère"
      :items="ongoingExternalMedications"
      :form="OngoingMedicationForm"
      create-label="Ajouter un traitement en cours"
      edit-label="Éditer un traitement en cours"
      :loading="isLoadingOngoingMedications"
      no-data-text="Aucun traitement en cours"
      :item-model-class="Medication"
      :patient="patient"
      :hide-add-button="!isCurrentUserPractitioner"
    >
      <template #item="{ item, edit }">
        <ongoing-medication-listing-item :item="item" :actions="getMedicationsItemActions(item, edit)" />
      </template>
    </patient-sidebar-panel-listing>
  </div>
</template>
<script>
import OngoingMedicationForm from '@/modules/patient/components/patientFile/ongoingMedications/OngoingMedicationForm.vue'
import PatientSidebarPanelListing from '@/modules/patient/components/patientFile/PatientSidebarPanelListing.vue'
import OngoingMedicationListingItem from '@/modules/patient/components/patientFile/ongoingMedications/OngoingMedicationListingItem.vue'
import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem'
import DeleteActionMenuItem from '@/components/ui/actionsMenu/classes/DeleteActionMenuItem'

import Medication from '@/modules/patient/models/Medication'
import Patient from '@/modules/patient/models/Patient'

import { mapActions, mapGetters } from 'vuex'

import { MEDICATION_TYPES } from '@/modules/patient/constants/medicationTypes'
import { deleteFromAPI } from '@/services/api'
import { mapMutations } from 'vuex'
import NovaTools from '@/nova-tools/NovaTools'

export default {
  name: 'OngoingMedicationListing',
  components: {
    OngoingMedicationListingItem,
    PatientSidebarPanelListing,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      OngoingMedicationForm,
      Medication,
    }
  },
  computed: {
    ...mapGetters('auth', ['isCurrentUserPractitioner']),
    ...mapGetters('patient', ['getPatientOngoingMedications', 'isLoadingOngoingMedications', 'getHasLoadedOngoingMedicationsByPatientUuid']),
    ongoingMedications () {
      return this.getPatientOngoingMedications(this.patient.getUuid())
    },
    ongoingExternalMedications () {
      return this.ongoingMedications.filter((item) => item.type === MEDICATION_TYPES.EXTERNAL)
    },
    ongoingInternalMedications () {
      return this.ongoingMedications.filter((item) => item.type === MEDICATION_TYPES.INTERNAL)
    },
  },
  async created () {
    if (! this.getHasLoadedOngoingMedicationsByPatientUuid(this.patient.getUuid()) && ! this.isLoadingOngoingMedications) {
      await this.fetchOngoingMedications(this.patient.getUuid())
    }
  },
  methods: {
    ...mapMutations('app', ['SET_SNACK']),
    ...mapActions('patient', ['fetchOngoingMedications']),
    getMedicationsItemActions (item, editMethod) {
      const actions = []

      if (this.isCurrentUserPractitioner) {
        actions.push(new ActionMenuItem(
          'edit',
          'Modifier',
          () => editMethod(item),
        ))
      }

      actions.push(new DeleteActionMenuItem({ callback: () => this.delete(item) }))

      return actions
    },
    delete (item) {
      NovaTools.dialog.confirm(
        'Supprimer le traitement',
        'Êtes-vous sûr(e) de vouloir supprimer le traitement',
        async () => {
          await deleteFromAPI(item.healthProductLine)
          await this.fetchOngoingMedications(this.patient.getUuid())
          this.SET_SNACK({ message: 'Le traitement a été supprimé avec succès' })
        })
    },
  },
}
</script>
<style scoped lang="scss">
</style>