<template>
  <div class="mb-4">
    <h3 class="subtitle-3 mb-1">
      Prescripteur
    </h3>
    <app-row>
      <app-col cols="12">
        <div class="flex-column flex-list-1">
          <app-dataset-item v-test="'practitioner-full-name'" label="Nom complet" :value="practitionerFullName" />
          <app-dataset-item v-test="'practitioner-rpps'" label="Numéro RPPS" :value="prescriber.rpps || null" />
          <app-dataset-item v-test="'practitioner-full-structure'" label="Structure" :value="structure" />
          <app-dataset-item v-test="'practitioner-psam'" label="Numéro AM" :value="prescriber.am || null" />
        </div>
      </app-col>
    </app-row>
  </div>
</template>

<script>

import AppDatasetItem from '@/components/ui/datasetItem/AppDatasetItem.vue'

export default {
  name: 'AatiPrescriberDetails',
  components: { AppDatasetItem },
  props: {
    prescriber: {
      type: Object,
      required: true,
    },
  },
  computed: {
    practitionerFullName () {
      return `${this.prescriber.firstName} ${this.prescriber.familyName}`
    },
    structure () {
      const finessStr = this.prescriber.finessStructure ? `FINESS ${this.prescriber.finessStructure}` : null
      const siretStr = this.prescriber.siretStructure ? `SIRET ${this.prescriber.siretStructure}` : null
      const structureArr = [this.prescriber.company, this.prescriber.address, finessStr, siretStr].filter(el => !! el)

      return structureArr.join(' - ')
    },
  },
}
</script>