<template>
  <validation-observer v-slot="{ valid }" slim>
    <aati-form-step
      v-test="'step'"
      class="medical-leave-context-fields"
      :step="step"
      :current-step="currentStep"
      :is-form-valid="valid"
      title="Contexte de l'arrêt de travail"
      @update-current-step="$emit('update:current-step', $event)"
    >
      <n-tooltip v-slot="{ on }" :text="thirdPartyAccidentDisabledTooltip" :disabled="thirdPartyAccidentEnabled">
        <div class="medical-leave-context-fields__third-party-section" v-on="on">
          <app-checkbox
            v-model="localValue.thirdPartyAccident"
            v-test="'third-party-accident-checkbox'"
            label="Suite à un accident causé par un tiers, intervenu le"
            :disabled="!thirdPartyAccidentEnabled"
            :ripple="false"
          />
          <app-date-picker
            ref="third-party-accident-date-input"
            v-model="localValue.thirdPartyAccidentDate"
            class="medical-leave-context-fields__third-party-section__date"
            :hide-hint="true"
            :disabled="! localValue.thirdPartyAccident"
            :max="thirdPartyAccidentMaxDate"
            :rules="{ 'required': !!localValue.thirdPartyAccident, 'before_now': true, beforeOrEqualStartDate: { startDate } }"
          />
          <app-icon
            v-if="localValue.thirdPartyAccident"
            icon="info"
            class="ml-2"
            color="secondary lighten-1"
            :tooltip="thirdPartyAccidentRegulatoryText"
          />
        </div>
      </n-tooltip>
      <n-tooltip v-slot="{ on }" :text="commonDisabledTooltip" :disabled="thermalCureEnabled">
        <span v-on="on">
          <app-checkbox
            v-model="localValue.thermalCure"
            class="medical-leave-context-fields__thermal-cure"
            label="Suite à une cure thermale"
            :disabled="!thermalCureEnabled"
            :ripple="false"
          />
        </span>
      </n-tooltip>
      <n-tooltip v-slot="{ on }" :text="warPensionerDisabledTooltip" :disabled="warPensionerEnabled">
        <span v-on="on">
          <app-checkbox
            v-model="localValue.warPensioner"
            class="medical-leave-context-fields__war-pensioner"
            label="En rapport avec l'affection pour laquelle le patient est pensionné de guerre"
            :disabled="!warPensionerEnabled"
            :ripple="false"
          />
        </span>
      </n-tooltip>
    </aati-form-step>
  </validation-observer>
</template>

<script>
import AatiFormStep from '@/modules/patient/components/sidebar/tlsi/aati/AatiFormStep.vue'
import AppCheckbox from '@/components/ui/form/AppCheckbox.vue'
import AppDatePicker from '@/components/ui/form/AppDatePicker.vue'
import { MEDICAL_LEAVE_INDICATION_DATA } from '@/modules/patient/components/sidebar/tlsi/aati/constants/medicalLeaveIndicationData'

import { ValidationObserver, extend } from 'vee-validate'

export default {
  name: 'MedicalLeaveMedicalInfosFields',
  components: {
    AatiFormStep,
    AppCheckbox,
    AppDatePicker,
    ValidationObserver,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    medicalData: {
      type: Object,
      default: null,
    },
    startDate: {
      type: String,
      default: null,
    },
    currentStep: {
      type: Number,
      default: 1,
    },
  },
  data () {
    return {
      MEDICAL_LEAVE_INDICATION_DATA,
      step: 4,
      localValue: null,
      thirdPartyAccidentRegulatoryText: 'Si l\'arrêt de travail est consécutif à un accident causé par un tiers, le bénéficiaire est tenu d\'en informer son organisme d\'assurance maladie.',
    }
  },
  computed: {
    fullTimeIndication () {
      const fullTimeFieldsetData = (({ ald, atmp, pregnancy, childDeath }) => ({
        ald,
        atmp,
        pregnancy,
        childDeath,
      }))(this.medicalData)
      return Object.keys(fullTimeFieldsetData).find(key => fullTimeFieldsetData[key] === true) ?? null
    },
    fullTimeIndicationLabel () {
      return MEDICAL_LEAVE_INDICATION_DATA.find(ind => ind.fullTime.name === this.fullTimeIndication)?.label ?? null
    },
    isIndicationCompatible () {
      return ! this.fullTimeIndication || this.fullTimeIndication === 'ald'
    },
    commonDisabledTooltip () {
      return this.fullTimeIndicationLabel ? `Non sélectionnable dans le cadre d'un arrêt en rapport avec ${this.fullTimeIndicationLabel}` : null
    },
    thirdPartyAccidentEnabled () {
      return this.isIndicationCompatible && ! this.localValue.warPensioner
    },
    thirdPartyAccidentDisabledTooltip () {
      if (this.localValue.warPensioner) {
        return 'Ne peut être sélectionné en simultané avec le contexte de pensionné de guerre'
      }
      return this.commonDisabledTooltip
    },
    thirdPartyAccidentMaxDate () {
      if (this.startDate && new Date(this.startDate) < new Date()) {
        return new Date(this.startDate)
      }
      return new Date()
    },
    thermalCureEnabled () {
      return this.isIndicationCompatible
    },
    warPensionerEnabled () {
      return this.isIndicationCompatible && ! this.localValue.thirdPartyAccident
    },
    warPensionerDisabledTooltip () {
      if (this.localValue.thirdPartyAccident) {
        return 'Ne peut être sélectionné en simultané avec le contexte d\'accident causé par un tiers'
      }
      return this.commonDisabledTooltip
    },
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.localValue = this.value
      },
    },
    localValue: {
      deep: true,
      handler () {
        this.$emit('input', this.localValue)
      },
    },
    'localValue.thirdPartyAccident' (newVal) {
      if (newVal) {
        requestAnimationFrame(() => this.$refs['third-party-accident-date-input']?.focus())
      } else {
        this.localValue.thirdPartyAccidentDate = null
      }
    },
    isIndicationCompatible (newVal) {
      if (! newVal) {
        this.resetContext()
      }
    },
  },
  methods: {
    resetContext () {
      this.localValue = {
        thirdPartyAccident: false,
        thirdPartyAccidentDate: null,
        thermalCure: false,
        warPensioner: false,
      }
    },
  },
}
extend('beforeOrEqualStartDate', {
  validate (value, { startDate }) {
    if (! startDate) {
      return true
    }
    const valueDateString = value.split('/').reverse().join('-')
    const formattedValue = new Date(valueDateString)
    const formattedStartDate = new Date(startDate)
    return formattedValue > formattedStartDate ? 'La date doit être antérieure ou égale à la date de début d\'arrêt' : true
  },
  params: ['startDate'],
})
</script>
<style lang="scss" scoped>
.medical-leave-context-fields {

    &__third-party-section,
    &__thermal-cure,
    &__war-pensioner {
      display: flex;
      align-items: center;
      width: max-content;
      max-width: 100%;

      ::v-deep {
        .app-checkbox--active .app-checkbox__label {
          color: var(--v-secondary-base);
        }
        .app-checkbox__label {
          color: var(--v-secondary-base);
        }
      }
    }

    &__third-party-section {
      ::v-deep {
        .app-date-picker__field {
          padding-top: 0;
          margin-left: 8px;
        }
      }
      &__date {
        width: max-content;

        ::v-deep {
          input {
            color: var(--v-secondary-base);
          }
        }
      }
    }

    &__thermal-cure,
    &__war-pensioner {
      height: 32px;
    }

    ::v-deep {
      .app-checkbox {
        &--active &__label {
          color: var(--v-primary-lighten4);
        }
      }
    }
}

</style>