export const TLSI_TYPES = Object.freeze({
  DMTI: {
    value: 'dmti',
    label: 'DMTi',
  },
  IMTI: {
    value: 'imti',
    label: 'IMTi',
  },
  ALDI: {
    value: 'aldi',
    label: 'ALDi',
  },
  AATI: {
    value: 'aati',
    label: 'AATi',
  },
})