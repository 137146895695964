import PrescriptionMedicineLine from '@/modules/patient/models/prescriptionLine/prescriptionHealthProductLine/PrescriptionMedicineLine'

import { HEALTH_PRODUCT_TYPES } from '@/modules/patient/components/consultation/prescription/constants/healthProductTypes'
import { UNSUBSTITUTION_PATTERNS } from '@/modules/patient/components/consultation/prescription/constants/unsubstitutionPatterns'
import NovaTools from '@/nova-tools/NovaTools'

export default class PrescriptionSpecialtyMedicineLine extends PrescriptionMedicineLine {
  constructor (options = {}) {
    super(options)

    this.posologies = options.posologies ?
      options.posologies
      : []
    this.product = options.product || null
    this.summaries = options.summaries || []
    this.startDate = options.startDate || null
    this.unsubstitutable = options.unsubstitutable || false
    this.unsubstitutionPattern = options.unsubstitutionPattern || null

    this.underProtocol = options.underProtocol || false
    this.rtu = options.rtu || false

    this.refundable = typeof (this.product?.is_reimbursed) !== 'undefined' ? this.product?.is_reimbursed : true
    this.exceptionDrug = this.product?.is_exceptional_drug || false

    this.endDate = options.endDate || null
    this.occupationalDisease = options.occupationalDisease || false
    this.meal = options.meal || null
    this.administrationRoute = options.administrationRoute || null
    this.reason = options.reason || null
    this.longTerm = options.longTerm || false
    this.uponRequest = options.uponRequest || false
    this.uponRequestDetails = options.uponRequestDetails || null
  }

  toString () {
    return `${this.getLabel()} ${this.summaries.join(' puis ')}`
  }

  getLabel () {
    return this.isBrandedDrug() && this.product.clinical_drug_label ? `${this.product.clinical_drug_label} (${this.product.brandname})` : this.product.label
  }

  /**
   * Permet de savoir si la spécialité est sécurisable
   * (Par exemple dans l'utilisation de widgets d'alerte)
   * @returns {Boolean}
   */
  isSecurable () {
    return this.isBrandedDrug() || this.isPharmaceuticalIngredient()
  }

  /**
   * Permet de savoir si la posologie de la spacialité peut être anaylsée
   * (Par exemple avec le widget PosologyChecker)
   * @returns {Boolean}
   */
  isPosologyAnalyzable () {
    return this.isBrandedDrug()
  }

  /**
   * Indique si la spécialité est un médicament
   * @returns {Boolean}
   */
  isBrandedDrug () {
    return this?.product?.type === HEALTH_PRODUCT_TYPES.BRANDED_DRUG
  }

  /**
   Indique si la spécialité est un produit pharmaceutique ou "DCI"
   * @returns {Boolean}
   */
  isPharmaceuticalIngredient () {
    return this?.product?.type === HEALTH_PRODUCT_TYPES.PHARMACEUTICAL_INGREDIENT
  }

  getTypeMetadata () {
    let label, description, backgroundColor

    if ([HEALTH_PRODUCT_TYPES.CLINICAL_DRUG, HEALTH_PRODUCT_TYPES.PHARMACEUTICAL_INGREDIENT].includes(this?.product?.type)) {
      backgroundColor = 'info'
      label = 'VIR'
      description = 'Spécialité virtuelle'
    }
    if (this.isBrandedDrug()) {
      backgroundColor = 'warning'
      label = `SPÉ${this.exceptionDrug ? ' | EXC' : ''}`
      description = this.exceptionDrug ? 'Médicament d\'exception' : 'Spécialité médicamenteuse'
    }

    if (! label || ! description || ! backgroundColor) {
      throw 'Les informations de cette ligne de prescription ne permettent pas de récupérer l\'ensemble de ses méta données.'
    }

    return {
      backgroundColor,
      label,
      description,
    }
  }

  /**
   * Détermine si la spécialité possède une durée
   * @returns {Boolean}
   */
  hasDuration () {
    const posologies = this.posologies || []
    return posologies.length > 0 && posologies.every(posology => posology.duration || this.longTerm)
  }

  hasOldFormatPosology () {
    return this.posologies.length > 0 && Object.keys(this.posologies[0]).includes('dosage')
  }

  /**
   * Détermine si la posologie de la spécialité est complète
   * @returns {Boolean}
   */
  hasCompletePosologies () {
    if (! this.posologies.length) {
      return false
    }

    return this.posologies.every(posology => {
      if (this.hasOldFormatPosology()) {
        const hasDuration = posology.duration || this.longTerm
        const hasDosage = !! Object.values(posology.dosage).find(q => !! q)
        const hasUnit = !! posology.doseUnit
        const hasValidFrequency = !! posology.frequency

        return hasDuration && hasDosage && hasUnit && hasValidFrequency
      }
      const hasDuration = !! posology.duration || this.longTerm
      const hasDosage = posology.posologyInstructions?.every(dosage => dosage.dose > 0)

      return hasDuration && hasDosage
    })
  }

  /**
   * Permet de récupérer le résumé de la ligne de prescription avec ou sans le nb de renouvellement
   * @param {Object} [withCountRenewal=false] Demande à renvoyer le nb de renouvellement
   * @returns {String} Le label du résumé de la ligne de prescription
   */
  getSummaries ({ withCountRenewal } = { withCountRenewal: false }) {
    if (withCountRenewal && this.renewalCount > 0) {
      return this.summaries.concat(this.getRenewalDetails())
    }
    return this.summaries
  }

  getSummariesWithoutPackageCount () {
    return this.summaries.filter(el => ! el.includes('boîte(s)'))
  }

  getCompletePosologyText () {
    const posologyArr = []

    const filteredSummaries = this.getSummariesWithoutPackageCount()
    if (filteredSummaries.length > 0) {
      let filteredSummariesSentence = filteredSummaries.join(', ')
      const lastChar = filteredSummariesSentence.at(- 1)
      if (lastChar !== '.') {
        filteredSummariesSentence += '.'
      }
      posologyArr.push(filteredSummariesSentence)
    }

    if (this.administrationRoute) {
      posologyArr.push(`Voie ${this.administrationRoute}.`)
    }

    if (this.longTerm) {
      posologyArr.push('TTT de fond.')
    }

    if (this.unsubstitutable) {
      posologyArr.push(`Non substituable (${this.getUnsubstitutionPatternText()}).`)
    }

    if (this.packageCount > 0) {
      posologyArr.push(`${this.packageCount} boîte(s).`)
    }

    if (this.renewalCount > 0) {
      posologyArr.push(`À renouveler ${this.renewalCount} fois.`)
    }

    if (this.motive) {
      posologyArr.push(`Motif: ${this.motive}.`)
    }

    if (this.startDate && NovaTools.dates.format(this.startDate, 'yyyy-MM-dd') !== NovaTools.dates.format(new Date(), 'yyyy-MM-dd')) {
      posologyArr.push(`À partir du ${NovaTools.dates.format(this.startDate, 'dd/MM/yyyy')}.`)
    }

    if (this.endDate) {
      posologyArr.push(`Jusqu'au ${NovaTools.dates.format(this.endDate, 'dd/MM/yyyy')}.`)
    }

    if (this.preventive) {
      posologyArr.push('En lien avec la prévention.')
    }

    if (this.underProtocol) {
      posologyArr.push('En lien avec un protocole antibiotique.')
    }

    if (this.outOfMa) {
      posologyArr.push('Hors AMM.')
    }

    if (this.rtu) {
      posologyArr.push('RTU.')
    }

    if (this.occupationalDisease) {
      posologyArr.push('En lien avec une maladie professionnelle.')
    }

    if (this.workAccident) {
      posologyArr.push('En lien avec un accident de travail.')
    }

    return posologyArr.length > 0 ? posologyArr.join(' ') : null
  }

  getUnsubstitutionPatternText () {
    return UNSUBSTITUTION_PATTERNS.find(pattern => pattern.value === this.unsubstitutionPattern)?.text || ''
  }

  isRefundable () {
    return this.refundable
  }

  getReimbursementRate () {
    return 100 * Number(this.product?.reimbursement_rate) || 0
  }

  isNarcotic () {
    return this.product?.is_narcotic || false
  }

  isExceptional () {
    return this.product?.is_exceptional_drug || false
  }

  getPrescriptionDuration () {
    return this.product?.prescription_duration || null
  }

  hasGenericAlternative () {
    return this.product?.is_available_as_generic || false
  }

  getPrescriptionConditions () {
    return this.product?.prescription_conditions || []
  }
}