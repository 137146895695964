import PrescriptionSpecialtyMedicineLine from '@/modules/patient/models/prescriptionLine/prescriptionHealthProductLine/PrescriptionMedicineLine/PrescriptionSpecialtyMedicineLine'

import { MEDICATION_TYPES } from '@/modules/patient/constants/medicationTypes'

export default class Medication extends PrescriptionSpecialtyMedicineLine {
  constructor (options = {}) {
    super(options)

    this.type = options.type || MEDICATION_TYPES.EXTERNAL
    this.chronic = options.chronic || false
    this.healthProductLine = options.healthProductLine || null
  }
}