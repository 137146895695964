import iCanopeeCardReaderInstance from '@/services/vendors/icanopee/classes/ICanopeeCardReader'
import Tlsi from '@/modules/patient/components/sidebar/tlsi/models/Tlsi'

import { postToAPI } from '@/services/api'

import { Buffer } from 'buffer'

/**
 * @typedef {Object} AatiType
 */
class Aati {

  /**
   * @param {AatiType} options
   */
  constructor (options = {}) {
    this.patient = options.patient
  }

  /**
   * Récupère le template d'une opération AATi sans données patient
   * @param {Object} softwareInformations Informations logicielles
   * @param {String} operation Nom de l'opération
   * @returns {String}
   */
  static async getAatiResourceTemplate (softwareInformations, operation) {
    const template = await iCanopeeCardReaderInstance.getAatiResourceTemplate(softwareInformations, operation)
    return Buffer.from(template).toString('base64')
  }

  /**
   * Récupère le template de déclaration d'AT
   * @param {Number|Object} beneficiaryVitaleCardIndex Informations du bénéficiaire
   * @param {String} byApCv Indique si l'identification du bénéficiaire est faite par appli CV
   * @returns {String}
   */
  async getAatiTemplate (beneficiaryVitaleCardIndex, byApCv) {
    const softwareInformations = await Tlsi.fetchSoftwareInformations()

    const template = await iCanopeeCardReaderInstance.getAatiTemplate(softwareInformations, beneficiaryVitaleCardIndex, byApCv)
    return Buffer.from(template).toString('base64')
  }

  /**
   * Envoie vers l'API easy-care les données à transmettre au téléservice AATi
   * @param {Object} requestParams Paramètres attendus par l'API
   * @returns {Object}
   */
  async sendAatiRequest (requestParams) {
    const { data } = await postToAPI('/api/tlsi/aati', {
      patient: this.patient['@id'],
      ...requestParams,
    }, { errorHandle: false })
    return data
  }

  /**
   * Enregistre le récapitulatif complet de l'arrêt de travail dans le dossier patient
   * @param {Object} requestParams Paramètres attendus par l'API
   * @returns {Object}
   */
  static async saveAatiFullSummaryToPatientFile (requestParams) {
    const { data } = await postToAPI('/api/tlsi/save_receipt', requestParams, { errorHandle: false })
    return data
  }
}

export default Aati