import { RESET_MUTATION } from '@/utils/functions/store'

import { capitalize } from 'lodash'
import { getInsuredBirthDate } from '@/services/vendors/icanopee/utils/dataNormalizer'

import { CARD_TYPES } from '@/services/card-reader/constants/cardTypes'
import { CIVILITIES, GENDERS } from '@/constants'

import Patient from '@/modules/patient/models/Patient'

export const state = () =>({
  pincodeModalVisible: false,
  pincode: null,
  isCardReaderListUpdating: false,
  cardReaders: [],
  hasCard: {
    [CARD_TYPES.VITALE]: false,
    [CARD_TYPES.CPX]: false,
  },
  isLoadingCard: {
    [CARD_TYPES.VITALE]: false,
    [CARD_TYPES.CPX]: false,
  },
  cardContent: {
    [CARD_TYPES.VITALE]: null,
    [CARD_TYPES.CPX]: null,
  },
  cardReader: {
    [CARD_TYPES.VITALE]: null,
    [CARD_TYPES.CPX]: null,
  },
})

export const getters = {
  getPincodeModalVisible: state => state.pincodeModalVisible,
  getPincode: state => state.pincode,
  getIsCardReaderListUpdating: state => state.isCardReaderListUpdating,
  getCardReaders: state => state.cardReaders || [],
  getHasCard: state => (cardType) => state.hasCard[cardType],
  getHasCpxCard: (state, getters) => !! getters.getCardContent(CARD_TYPES.CPX),
  getHasVitaleCard: (state, getters) => !! getters.getCardContent(CARD_TYPES.VITALE),
  getIsLoadingCard: state => (cardType) => state.isLoadingCard[cardType],
  getVitaleCardInsureds: (state, getters) => getters.getCardContent(CARD_TYPES.VITALE),
  getCardContent: (state, getters, storeState, storeGetters) => (cardType) => {
    if (cardType === CARD_TYPES.CPX) {
      return state.cardContent[cardType]
    }

    return state.cardContent[cardType]?.map((patient, index) => {
      const isMainInsured = index === 0
      const nir = patient.s_nir.replace(' ', '')

      let gender = GENDERS.UNKNOWN.value
      let civility = null
      // Le numéro de sécu pouvant être le même que l'assuré principal,
      // il n'est pas possible de déterminer le genre avec certitude
      // pour un bénéficiaire autre que l'assuré principal.
      if (isMainInsured) {
        gender = nir ? GENDERS[nir[0] === '1' ? 'MALE' : 'FEMALE'].value : GENDERS.UNKNOWN.value
        civility = gender !== GENDERS.UNKNOWN.value ? CIVILITIES[gender === GENDERS.MALE.value ? 'MR' : 'MRS'].value : null
      }

      // Gestion de l'adresse
      let address = {}
      if (Object.keys(patient).includes('s_addressLine1')) {
        const addressSplitCpAndCity = patient.s_addressLine5.split(/ (.*)/, 2).filter(el => !! el) // Séparer au premier espace seulement
        address = {
          line1: patient.s_addressLine3 || null,
          line2: [patient.s_addressLine1, patient.s_addressLine2, patient.s_addressLine4].filter(el => !! el).join(' - ') || null,
          postalCode: addressSplitCpAndCity.length === 2 ? addressSplitCpAndCity[0] : null,
          city: addressSplitCpAndCity.length === 2 ? addressSplitCpAndCity[1] : null,
        }
      }
      return {
        patient: new Patient({
          firstName: capitalize(patient.s_given),
          firstNames: capitalize(patient.s_given),
          birthName: capitalize(patient.s_birthname || patient.s_name),
          familyName: capitalize(patient.s_name),
          birthDate: getInsuredBirthDate(patient.s_rawBirthdayYYYYMMDD),
          gender,
          civility,
          nir,
          billDataset: {
            birthRank: patient.i_birthRank,
            fund: patient.HealthCoverage.s_desk,
            center: patient.HealthCoverage.s_center,
            grandRegime: storeGetters['app/getGrandRegimes'].find(grandRegime => grandRegime.code === patient.HealthCoverage.s_regime),
            birthDate: getInsuredBirthDate(patient.s_rawBirthdayYYYYMMDD, { ignoreLunarDate: true }),
            quality: patient.i_quality, // patient.dob_qualite_beneficiaire,
          },
          address,
        }),
        isMainInsured,
        vitaleCardIndex: index,
      }
    })
  },
  getIsPractitionerDoctor: (state, getters) => {
    const cardContent = getters.getCardContent(CARD_TYPES.CPX)
    return (cardContent?.s_profession === '10' && cardContent?.s_professionDescription === 'Médecin')
  },
  getCardReader: state => (cardType) => state.cardReader[cardType],
}

export const mutations = {
  SET_PINCODE_MODAL_VISIBLE (state, pincodeModalVisible) {
    state.pincodeModalVisible = pincodeModalVisible
  },
  SET_PINCODE (state, pincode) {
    state.pincode = pincode
  },
  SET_CARD_READER_CARD_STATE (state, { cardReaderType, hasCard }) {
    if (cardReaderType === CARD_TYPES.CPX) {
      if (! hasCard) {
        state.pincode = null
      }
    }

    state.hasCard[cardReaderType] = hasCard
  },
  SET_IS_LOADING_CARD (state, { cardReaderType, isLoading }) {
    state.isLoadingCard[cardReaderType] = isLoading
  },
  SET_CARD_CONTENT (state, { cardReaderType, content }) {
    state.cardContent[cardReaderType] = content
  },
  SET_CARD_READER (state, { cardReaderType, cardReader }) {
    state.cardReader[cardReaderType] = cardReader
  },
  SET_CARD_READERS (state, cardReaders) {
    state.cardReaders = cardReaders
  },
  SET_CARD_READERS_LIST_UPDATING (state, isUpdating) {
    state.isCardReaderListUpdating = isUpdating
  },
  [RESET_MUTATION] (state) {
    state.pincode = null
    state.pincodeModalVisible = false
  },
}
export const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}