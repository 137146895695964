<template>
  <div class="px-4 py-2">
    <n-alert
      v-if="uncodifiedPathologies.length"
      v-test="'uncodified-pathologies-alert'"
      type="warning"
      transparent
      title="Certaines pathologies seront ignorées pour la sécurisation LAP"
      message="Suite à la migration des pathologies sur le dictionnaire CIM-10, il est nécessaire de saisir à nouveau les pathologies pour qu'elles soient utilisées dans la sécurisation du LAP"
    />
    <n-list
      :loading="isLoading"
      :spacing="1"
      hoverable
      :inset-x="4"
      empty-text="Aucune pathologie"
      :items="pathologies"
    >
      <template #item="{ item }">
        <patient-sidebar-risk-factor v-test="'pathology'" :risk-factor="item" :actions="getPathologieActions(item)" />
      </template>
      <template #skeleton>
        <app-skeleton-loader
          v-test="'pathology-listing-skeleton'"
          type="list-item-two-line"
          class="ma-0 pa-0"
        />
      </template>
    </n-list>
    <app-modal-form
      v-model="editingPathology"
      v-test="'pathology-form'"
      width="500"
      :title="pathologyModalTitle"
      :form="PathologyForm"
      :scrollable="false"
      :patient="patient"
      @cancel="editingPathology = null"
      @submitSuccess="editingPathology = null"
    />
    <pathology-delete-confirm-modal v-model="deletingPathology" v-test="'delete-confirm-modal'" />
  </div>
</template>

<script>
import AppModalForm from '@/components/ui/modal/AppModalForm.vue'
import AppSkeletonLoader from '@/components/ui/loaders/AppSkeletonLoader.vue'
import PathologyForm from '@/modules/patient/components/patientFile/pathologies/PathologyForm.vue'
import PathologyDeleteConfirmModal from '@/modules/patient/components/patientFile/pathologies/PathologyDeleteConfirmModal.vue'
import PatientSidebarRiskFactor from '@/modules/patient/components/PatientSidebarRiskFactor.vue'

import { mapActions, mapGetters } from 'vuex'

import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem'
import Patient from '@/modules/patient/models/Patient'
import Pathology from '@/modules/patient/models/riskFactors/Pathology'

export default {
  name: 'PathologyListing',
  components: {
    AppModalForm,
    AppSkeletonLoader,
    PathologyDeleteConfirmModal,
    PatientSidebarRiskFactor,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      PathologyForm,
      deletingPathology: null,
      editingPathology: null,
    }
  },
  computed: {
    ...mapGetters('patient', ['getPatientPathologies', 'isLoadingPathologies', 'getHasLoadedPathologiesByPatientUuid']),
    pathologies () {
      const pathologies = this.getPatientPathologies(this.patient.getUuid())
      return [
        ...pathologies.filter(item => item.important).sort((a, b) => a.label.localeCompare(b.label)),
        ...pathologies.filter(item => ! item.important).sort((a, b) => a.label.localeCompare(b.label)),
      ]
    },
    hasLoadedPathologies () {
      return this.getHasLoadedPathologiesByPatientUuid(this.patient.getUuid())
    },
    isLoading () {
      return this.isLoadingPathologies
    },
    pathologyModalTitle () {
      return `${this.editingPathology?.['@id'] ? 'Modifier' : 'Ajouter'} une pathologie`
    },
    uncodifiedPathologies () {
      return this.pathologies.filter(pathology => ! pathology.icd10Code)
    },
  },
  watch: {
    patient: {
      immediate: true,
      handler (patient) {
        if (patient['@id'] && ! this.hasLoadedPathologies && ! this.isLoading) {
          this.fetchPatientPathologies(this.patient.getUuid())
        }
      },
    },
  },
  methods: {
    ...mapActions('patient', ['fetchPatientPathologies']),
    /**
     * Permet l'ouverture du formulaire de création par le composant parent
     */
    openForm () {
      this.editingPathology = new Pathology()
    },
    getPathologieActions (pathology) {
      return [
        new ActionMenuItem('edit', 'Éditer', () => this.editingPathology = pathology),
        new ActionMenuItem('delete', 'Supprimer', () => this.deletingPathology = pathology),
      ]
    },
  },
}
</script>