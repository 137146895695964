<template>
  <validation-provider
    slim
    :rules="rules"
    :name="label"
    :vid="name"
  >
    <v-checkbox
      v-model="localInputValue"
      :value="value"
      class="app-checkbox"
      :class="{
        'app-checkbox--active': isSelected,
        'app-checkbox--disabled': disabled
      }"
      :hide-details="hideErrors"
      :indeterminate="indeterminate"
      :readonly="readonly"
      :disabled="disabled"
      :ripple="ripple"
      @change="value => $emit('change', value)"
      @click="$emit('click')"
    >
      <template #label>
        <span class="app-checkbox__label">
          <slot name="label">{{ fieldLabel }}</slot>
        </span>
      </template>
    </v-checkbox>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import fieldMixin from '@/mixins/fields.js'

import localCopyMixin from '@novalys/src/mixins/local-copy-mixin'

/**
 * Checkbox de l'application
 * @see https://vuetifyjs.com/en/components/checkboxes/
 */
export default {
  name: 'AppCheckbox',
  components: { ValidationProvider },
  mixins: [
    localCopyMixin({
      propertyName: 'inputValue',
      copyPropertyName: 'localInputValue',
      event: 'change',
    }),
    fieldMixin,
  ],
  model: {
    prop: 'inputValue',
    event: 'change',
  },
  props: {
    /**
     * Modèle du composant
     * @model
     */
    inputValue: {
      type: [Array, Boolean, String, Object],
      default: null,
    },
    /**
     * Affiche la checkbox dans un état indéterminé
     * @default false
     */
    indeterminate: {
      type: Boolean,
      default: false,
    },
    ripple: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isSelected () {
      if (Array.isArray(this.inputValue)) {
        return this.inputValue.indexOf(this.value) >= 0
      }
      if (! this.value) {
        return !! this.inputValue
      }
      return this.inputValue === this.value
    },
  },
}
</script>

<style lang="scss" scoped>
.app-checkbox {
  margin-top: 0;
  padding-top: 0;

  &--active &__label {
    color: var(--v-primary-base)
  }
  &--disabled &__label {
    opacity: .5;
    color: var(--v-secondary-base);
  }
  &__label {
    font-size: 13px;
    color: var(--v-content-base);
  }

  ::v-deep {
    .v-input--selection-controls {
      &__ripple {
        width: 30px;
        height: 30px;
        left: -14px;
        top: calc(50% - 22px);
      }

      &__input {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>