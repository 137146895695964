<template>
  <app-form :submit-method="sendAatiRequest" class="aati-confirmation-form">
    <app-panel title="Récapitulatif avant transmission" is-outlined is-flat>
      <aati-beneficiary-details :beneficiary="beneficiary" />
      <aati-prescriber-details :prescriber="formData.prescriberData" />
      <h3 v-test="'main-data'" class="subtitle-3 mb-2">
        Arrêt {{ typeText }} à {{ natureText }}
      </h3>
      <div class="flex-column flex-list-1">
        <app-dataset-item
          v-if="formData.medicalLeaveData.type === 'prolongation'"
          v-test="'prescriber-data'"
          label="Prolongé par le médecin"
          :value="prescriberText"
        />
        <app-dataset-item
          v-if="indicationText"
          v-test="'indication-data'"
          label="En rapport avec"
          :value="indicationText"
        />
        <app-dataset-item
          v-if="formData.medicalLeaveData.motiveLabel"
          v-test="'motive-data'"
          label="Motif"
          :value="motiveText"
        />
        <app-dataset-item
          v-if="hasContext"
          v-test="'context-data'"
          label="Contexte"
          :value="contextText"
        />
        <app-dataset-item v-test="'main-duration-data'" :label="mainDurationLabel" :value="mainDurationText" />
        <app-dataset-item
          v-if="formData.durationData.endPartialDate"
          v-test="'second-duration-data'"
          label="Durée de la reprise à temps partiel"
          :value="secondDurationText"
        />
        <app-dataset-item
          v-if="employerText"
          v-test="'employer-data'"
          label="Employeur"
          :value="employerText"
        />
        <app-dataset-item
          v-if="visitingAddressText"
          v-test="'visiting-address-data'"
          label="Adresse de visite"
          :value="visitingAddressText"
        />
        <app-dataset-item v-test="'outing-data'" label="Sorties autorisées" :value="outingText" />
        <app-dataset-item v-test="'activity-data'" label="Activités autorisées" :value="activityText" />
      </div>
    </app-panel>
    <app-panel dense is-flat>
      <div v-test="'legislation-text'" class="flex-list-2 flex-column secondary--text my-4">
        <p>
          Le remplissage de la déclaration résulte de textes du code de la sécurité sociale et non de la volonté
          unilatérale de l'assurance maladie : art. L.162-4-1-1er al., L.162-4-4, L.315-2, L.321-1-5ème al., L. 323-6,
          L.
          376-1, L. 613-20, R. 321-2, R. 323-11-1, D. 323-2, R.441-10, L.433-1, R.433-15, D. 613-19, D. 613-23 du Code
          de
          la sécurité sociale, L. 732-4 et 762-18-1 du Code rural et de la pêche maritime.
        </p>
        <p>
          La loi rend passible d'amende et/ou d'emprisonnement quiconque se rend coupable de fraudes ou de fausses
          déclarations (articles 313-1 à 313-3, 433-19, 441-1 et suivants du Code pénal, article L.114-17-1 du Code de
          la
          sécurité sociale).
        </p>
        <p>
          Conformément aux dispositions relatives à la protection des données personnelles, le professionnel de santé et
          le patient disposent d'un droit d'accès et de rectification des données les concernant et contenues dans le
          formulaire ainsi que d'un droit à certaines limitations de leur traitement. Le professionnel de santé et le
          patient peuvent obtenir des informations sur le traitement de leurs données auprès du Directeur de la Caisse
          d'Assurance Maladie qui gère leur dossier et, en cas de difficultés, ils peuvent introduire une réclamation
          auprès de la CNIL.
        </p>
      </div>
      <div class="aati-confirmation-form__consent">
        <app-icon icon="info" color="secondary lighten-1" :tooltip="consentText" />
        <app-checkbox
          v-model="localFormData.medicalLeaveData.patientConsent"
          v-test="'consent-checkbox'"
          label="Mon patient accepte que je transmette le présent arrêt de travail pour son compte et est informé des textes applicables. J'accepte les conditions de la transmission et j'ai pris connaissance des textes applicables."
          rules="checked|required"
          :ripple="false"
        />
      </div>
    </app-panel>
  </app-form>
</template>

<script>
import AppForm from '@/components/ui/form/AppForm.vue'
import AatiBeneficiaryDetails from '@/modules/patient/components/sidebar/tlsi/aati/AatiBeneficiaryDetails'
import AatiPrescriberDetails from '@/modules/patient/components/sidebar/tlsi/aati/AatiPrescriberDetails'
import AppPanel from '@/components/ui/panel/AppPanel.vue'
import AppDatasetItem from '@/components/ui/datasetItem/AppDatasetItem.vue'
import AppCheckbox from '@/components/ui/form/AppCheckbox.vue'

import { MEDICAL_LEAVE_NATURE_DATA } from '@/modules/patient/components/sidebar/tlsi/aati/constants/medicalLeaveNatureData'
import { MEDICAL_LEAVE_INDICATION_DATA } from '@/modules/patient/components/sidebar/tlsi/aati/constants/medicalLeaveIndicationData'
import { MEDICAL_LEAVE_PRESCRIBER_DATA } from '@/modules/patient/components/sidebar/tlsi/aati/constants/medicalLeavePrescriberData'

import { pluralize } from '@/utils/functions/words'
import { add, differenceInMinutes } from '@/utils/functions/dates'

import Patient from '@/modules/patient/models/Patient'
import Aati from '@/modules/patient/components/sidebar/tlsi/aati/models/Aati'
import NovaTools from '@/nova-tools/NovaTools'

export default {
  name: 'AatiConfirmationForm',
  components: {
    AppForm,
    AatiBeneficiaryDetails,
    AatiPrescriberDetails,
    AppPanel,
    AppDatasetItem,
    AppCheckbox,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
    beneficiary: {
      type: Patient,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      MEDICAL_LEAVE_NATURE_DATA,
      MEDICAL_LEAVE_INDICATION_DATA,
      MEDICAL_LEAVE_PRESCRIBER_DATA,
      localFormData: null,
      consentText: `La transmission équivaut à ma signature de la partie du document concernant les renseignements médicaux.
        Elle fait foi du contenu du document jusqu'à preuve du contraire et sous réserve de son étude par les services
        concernés.
        Elle génère l'envoi de l'original du document sur un espace dédié qui garantit le respect du secret médical et
        n'est accessible qu'aux personnes habilitées.
        Seul l'assuré est responsable de la véracité des informations non médicales déclarées : adresse de visite,
        activité, accident causé par un tiers, suite à une cure thermale, affection pensionné de guerre.
        L'accusé de réception ne fait foi que de la date et heure de ladite réception. Cette date est toujours calculée
        sur le fuseau horaire de Paris.`,
    }
  },
  computed: {
    natureText () {
      return MEDICAL_LEAVE_NATURE_DATA.find(nature => nature.value === this.formData.medicalLeaveData.nature)?.label
    },
    typeText () {
      return this.formData.medicalLeaveData.type
    },
    prescriberText () {
      return this.formData.prescriberData.label ?? MEDICAL_LEAVE_PRESCRIBER_DATA.find(prescr => prescr.value === this.formData.prescriberData.code)?.label
    },
    indicationData () {
      if (this.formData.medicalLeaveData.nature === 'TP') {
        return (({ aldPartial, atmpPartial }) => ({
          aldPartial,
          atmpPartial,
        }))(this.formData.medicalLeaveData)
      }
      return (({ ald, atmp, pregnancy, childDeath }) => ({
        ald,
        atmp,
        pregnancy,
        childDeath,
      }))(this.formData.medicalLeaveData)
    },
    indicationText () {
      const indication = Object.keys(this.indicationData).find(key => this.indicationData[key] === true) ?? null
      const availabilityProp = (this.formData.medicalLeaveData.nature === 'TP') ? 'partialTime' : 'fullTime'
      const indicationLabel = MEDICAL_LEAVE_INDICATION_DATA.find(ind => ind[availabilityProp].name === indication)?.label ?? null
      return indicationLabel ? `${indicationLabel} ${this.getIndicationDateText(availabilityProp)}` : null
    },
    motiveText () {
      const complement = this.formData.medicalLeaveData.motiveInformation ? `(${this.formData.medicalLeaveData.motiveInformation})` : ''
      return `${this.formData.medicalLeaveData.motiveLabel} ${ complement }`
    },
    hasContext () {
      return this.formData.backgroundData.thirdPartyAccident
        || this.formData.backgroundData.thermalCure
        || this.formData.backgroundData.warPensioner
    },
    contextText () {
      const context = []

      if (this.formData.backgroundData.thirdPartyAccident) {
        context.push(`Accident causé par un tiers (en date du ${NovaTools.dates.format(this.formData.backgroundData.thirdPartyAccidentDate, 'dd/MM/yyyy')})`)
      }
      if (this.formData.backgroundData.thermalCure) {
        context.push('Cure thermale')
      }
      if (this.formData.backgroundData.warPensioner) {
        context.push('Pensionné de guerre')
      }
      return context.join(', ')
    },
    mainDurationLabel () {
      return `Durée${this.formData.durationData.endPartialDate ? ' de l\'arrêt à temps complet' : ''}`
    },
    mainDurationText () {
      const start = NovaTools.dates.newDate(this.formData.durationData.startDate)
      const end = NovaTools.dates.newDate(this.formData.durationData.endDate)
      return this.getDurationText(start, end)
    },
    secondDurationText () {
      const start = add(NovaTools.dates.newDate(this.formData.durationData.endDate), { days: 1 }) // début de la reprise à tps partiel = lendemain fin tps complet
      const end = NovaTools.dates.newDate(this.formData.durationData.endPartialDate)
      return this.getDurationText(start, end)
    },
    employerText () {
      if (this.formData.employerData.company) {
        const fullAddress = [
          this.formData.employerData.address.street,
          this.formData.employerData.address.zipcode,
          this.formData.employerData.address.city,
        ].filter(val => !! val).join(' ')

        return [
          this.formData.employerData.company,
          fullAddress,
          this.formData.employerData.phone,
          this.formData.employerData.email,
        ].filter(val => !! val).join(' - ')
      }
      return null
    },
    visitingAddressText () {
      if (this.formData.patientVisitingAddressData.street) {
        const elements = [
          this.formData.patientVisitingAddressData.street,
          `${this.formData.patientVisitingAddressData.zipcode} ${this.formData.patientVisitingAddressData.city}`,
        ]
        if (this.formData.employerData.phone) {
          elements.push(this.formData.patientVisitingAddressData.phone)
        }
        return elements.join(' - ')
      }
      return null
    },
    outingText () {
      if (! this.formData.outingData.outing) {
        return 'Non'
      }
      const outingText = this.formData.outingData.outingDate !== this.formData.outingData.freeTimeDate
        ? `à partir du ${NovaTools.dates.format(this.formData.outingData.outingDate, 'dd/MM/yyyy')}, `
        : ''
      const freeTimeOutingText = this.formData.outingData.freeTime
        ? `sans restriction à partir du ${NovaTools.dates.format(this.formData.outingData.freeTimeDate, 'dd/MM/yyyy')} (${this.formData.outingData.motiveFreeTime})`
        : ''

      return `Oui, ${outingText}${freeTimeOutingText}`
    },
    activityText () {
      if (! this.formData.activityData.activity) {
        return 'Non'
      }
      return `Oui, à partir du ${NovaTools.dates.format(this.formData.activityData.activityDate, 'dd/MM/yyyy') }`
    },
  },
  watch: {
    formData: {
      immediate: true,
      handler () {
        this.localFormData = this.formData
      },
    },
  },
  methods: {
    async sendAatiRequest () {
      try {
        const aatiInstance = new Aati({ patient: this.patient })
        return await aatiInstance.sendAatiRequest(this.localFormData)
      } catch (error) {
        if (typeof error === 'object' && error.response && error.response.data) {
          NovaTools.notify.error(error.response.data['hydra:description'], {
            title: error.response.data['hydra:title'],
            timeout: - 1,
          })
        } else {
          NovaTools.notify.error('Une erreur est survenue')
          throw error
        }
      }
    },
    getIndicationDateText (timePart) {
      let date = null
      if (timePart === 'fullTime' && this.formData.medicalLeaveData.atmpDate) {
        date = this.formData.medicalLeaveData.atmpDate
      }
      if (timePart === 'fullTime' && this.formData.medicalLeaveData.childDeathDate) {
        date = this.formData.medicalLeaveData.childDeathDate
      }
      if (timePart === 'partialTime' && this.formData.medicalLeaveData.atmpPartialDate) {
        date = this.formData.medicalLeaveData.atmpPartialDate
      }
      return date ? `(en date du ${NovaTools.dates.format(date, 'dd/MM/yyyy')})` : ''
    },
    getDurationText (start, end) {
      const duration = Math.ceil(differenceInMinutes(add(end, { days: 1 }), start) / (60 * 24)) // le dernier jour est inclus dans la durée
      const startStr = start.toLocaleDateString('fr-FR', {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      })

      const endStr = end.toLocaleDateString('fr-FR', {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      })
      return `${duration} ${pluralize('jour', duration)}, du ${startStr} au ${endStr} inclus`
    },
  },
}
</script>
<style lang="scss" scoped>
.aati-confirmation-form__consent {
  display: flex;
  align-items: center;
  gap: map-get($spacers, 2);
}
</style>