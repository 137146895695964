<template>
  <validation-observer v-slot="{ valid }" slim>
    <aati-form-step
      :step="step"
      :current-step="currentStep"
      :is-form-valid="valid"
      title="Situation professionnelle du patient"
      @update-current-step="$emit('update:current-step', $event)"
    >
      <div class="pb-2">
        <app-row>
          <app-col cols="12">
            <app-text-field
              v-model="localValue.company"
              v-test="'company-input'"
              :rules="{ max: '60', required: isCompanyRequired }"
              label="Raison sociale"
            />
          </app-col>
        </app-row>
        <app-row>
          <app-col cols="12">
            <app-text-field v-model="localValue.address.street" label="N° et nom de la voie" />
          </app-col>
        </app-row>
        <app-row>
          <app-col cols="3">
            <app-text-field v-model="localValue.address.zipcode" rules="numeric|max:5" label="Code postal" />
          </app-col>
          <app-col cols="9">
            <app-text-field v-model="localValue.address.city" label="Ville" />
          </app-col>
        </app-row>
        <app-row>
          <app-col cols="3">
            <app-text-field
              v-model="localValue.phone"
              v-mask="'## ## ## ## ##'"
              rules="regex:^(?:\s*\d){10}$"
              label="Téléphone"
            />
          </app-col>
          <app-col cols="9">
            <app-text-field v-model="localValue.email" rules="email" label="Email" />
          </app-col>
        </app-row>
      </div>
    </aati-form-step>
  </validation-observer>
</template>

<script>
import AatiFormStep from '@/modules/patient/components/sidebar/tlsi/aati/AatiFormStep.vue'
import AppTextField from '@/components/ui/form/AppTextField.vue'

import { ValidationObserver } from 'vee-validate'

export default {
  name: 'MedicalLeaveEmployerFields',
  components: {
    AatiFormStep,
    AppTextField,
    ValidationObserver,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    currentStep: {
      type: Number,
      default: 1,
    },
  },
  data () {
    return {
      step: 6,
      localValue: null,
    }
  },
  computed: {
    isCompanyRequired () {
      return !! this.localValue.address.street || !! this.localValue.address.zipcode || !! this.localValue.address.city || !! this.localValue.address.phone || !! this.localValue.address.email
    },
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.localValue = this.value
      },
    },
    localValue: {
      deep: true,
      handler () {
        this.$emit('input', this.localValue)
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.row + .row {
  margin-top: 0 !important;
}
</style>