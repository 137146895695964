import { buildWidget, tokenService } from '@/services/synapse'

export default {
  props: {
    prescriptions: {
      type: Array,
      default: () => ([]),
    },
  },
  data () {
    return {
      widget: buildWidget(this.$options.name),
      isInitialised: false,
      initialisationData: null,
    }
  },
  computed: {
    securablePrescriptions () {
      return this.prescriptions.filter(medicineLine => medicineLine.isSecurable())
    },
    posologyAnalyzablePrescriptions () {
      return this.prescriptions.filter(medicineLine => medicineLine.isPosologyAnalyzable())
    },
    includesNotAnalysablePrescriptions () {
      return this.prescriptions.some(medicineLine => ! medicineLine.isPosologyAnalyzable())
    },
  },
  methods: {
    initWidget (data, options = {}) {
      this.initialisationData = data

      if (! tokenService.getToken()) {
        return new Promise((res) => {
          tokenService.onRefresh(() => {
            this.widget.init(this.$refs.widget, this.initialisationData, options)
            this.isInitialised = true
            res()
          })
        })
      }
      this.widget.init(this.$refs.widget, this.initialisationData, options)
      this.isInitialised = true
    },
    updateWidget (data) {
      if (! this.isInitialised) {
        this.initialisationData = data
        return
      }

      if (! tokenService.getToken()) {
        return new Promise((res) => {
          tokenService.onRefresh(() => {
            this.widget.update(data)
            res()
          })
        })
      }
      this.widget.update(data)
    },
    destroyWidget () {
      this.widget.destroy()
    },
    preventEnterSubmit (event) {
      if (event.code !== 'Enter') {
        return
      }
      event.preventDefault()
    },
  },
  async created () {
    // Évite de refresh 2 fois le token
    if (tokenService.isRefreshing) {
      return
    }
    if (! tokenService.getToken()) {
      tokenService.refreshTokenAsync()
    }
  },
  mounted () {
    if (! this.$refs.widget) {
      throw new Error('Une ref "widget" doit être fournie pour afficher le widget.')
    }
    this.$refs.widget.addEventListener('keydown', this.preventEnterSubmit, false)

    this.widget.setCallback('hasResults', results => this.$emit('has-results-update', results))
  },
  beforeDestroy () {
    this.$refs.widget.removeEventListener('keydown', this.preventEnterSubmit, false)
  },
  destroyed () {
    this.destroyWidget()
    this.widget.onDestroy()
  },
}