<template>
  <div class="flex-list-2 flex-column mb-4">
    <h3 class="subtitle-3">
      Bénéficiaire
    </h3>
    <app-row>
      <app-col cols="12" md="6">
        <div class="flex-column flex-list-1">
          <app-dataset-item v-test="'beneficiary-fullname'" label="Nom complet" :value="beneficiary.getCivilState()" />
          <app-dataset-item v-test="'beneficiary-birth-date'" label="Date de naissance" :value="beneficiary.getFormattedBirthDate()" />
        </div>
      </app-col>
      <app-col cols="12" md="6">
        <div class="flex-column flex-list-1">
          <app-dataset-item v-test="'beneficiary-nir'" label="NIR" :value="beneficiary.nir" />
          <app-dataset-item v-test="'beneficiary-birth-rank'" label="Rang de naissance" :value="beneficiary.billDataset.birthRank" />
        </div>
      </app-col>
    </app-row>
  </div>
</template>

<script>

import AppDatasetItem from '@/components/ui/datasetItem/AppDatasetItem.vue'

import Patient from '@/modules/patient/models/Patient'

export default {
  name: 'AatiBeneficiaryDetails',
  components: { AppDatasetItem },
  props: {
    beneficiary: {
      type: Patient,
      required: true,
    },
  },
}
</script>