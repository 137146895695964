<template>
  <v-tooltip
    ref="tooltip"
    v-model="localValue"
    content-class="n-tooltip"
    :activator="activator"
    :disabled="disabled"
    :bottom="! right"
    :open-delay="openDelay"
    :right="right"
  >
    <div>
      <div class="n-tooltip__arrow" :style="arrowStyle" />
      <!-- @slot Le contenu affiché dans la tooltip -->
      <slot name="content">
        <div v-sanitize="text" />
      </slot>
    </div>
    <template #activator="{ on, attrs }">
      <!--
        @slot Le déclencheur de la tooltip
          @binding {Object} on, Les évènements permettant d'afficher ou de masquer la tooltip
          @binding {Object} attrs Les attributs HTML permettant au navigateur de mieux identifier l'élément comme étant une tooltip
      -->
      <slot v-bind="{ attrs, on }" />
    </template>
  </v-tooltip>
</template>

<script>
import localCopyMixin from '@novalys/src/mixins/local-copy-mixin'

const ARROW_PX_SIZE = 8

/**
 * Permet d'afficher une info-bulle au survol d'un élément, facilitant ainsi la transmition d'informations pour un utilisateur
 */
export default {
  name: 'NTooltip',
  mixins: [localCopyMixin()],
  props: {
    /**
     * L'élément servant d'activateur à la tooltip
     *
     * Peut être un sélecteur ou bien un élément HTML
     */
    activator: {
      type: [String, Object, Element],
      default: null,
    },
    /**
     * Le délai d'affichage de la tooltip en millisecondes
     */
    openDelay: {
      type: String,
      default: '300',
    },
    /**
     * Le texte à afficher dans la tooltip
     */
    text: {
      type: String,
      default: null,
    },
    /**
     * Place la tooltip en dessous de l'activateur
     */
    bottom: {
      type: Boolean,
      default: true,
    },
    /**
     * Place la tooltip à droite de l'activateur
     */
    right: {
      type: Boolean,
      default: false,
    },
    /**
     * Permet de désactiver la tooltip, le survol de l'activateur n'aura donc aucun effet
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Indique si la tooltip est visible
     */
    value: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      arrowTop: 0,
      arrowLeft: 0,
    }
  },
  computed: {
    arrowStyle () {
      return {
        top: `${this.arrowTop}px`,
        left: `${this.arrowLeft}px`,
        width: `${ARROW_PX_SIZE}px`,
        height: `${ARROW_PX_SIZE}px`,
      }
    },
  },
  watch: {
    async text () {
      await this.$nextTick()
      requestAnimationFrame(this.setArrowPosition)
    },
    async localValue (isTooltipVisible) {
      if (isTooltipVisible) {
        await this.$nextTick()
        requestAnimationFrame(this.setArrowPosition)
      }
    },
  },
  mounted () {
    // La gestion personnalisée des slots de ce composant engendre son certains cas la perte de réactivité
    // de certains composants (par exemple les boutons).
    // La mise en place d'une directive "v-tootlip" devrait permettre d'éviter cet effet de bord
    this.$forceUpdate()
  },
  methods: {
    setArrowPosition () {
      if (! this.$refs.tooltip?.activatorElement) {
        return
      }
      const { top, left, height, width } = this.$refs.tooltip.activatorElement.getBoundingClientRect()
      if (this.right) {
        this.arrowTop = (top - parseInt(this.$refs.tooltip.calculatedTop, 10)) + ((height / 2) - (ARROW_PX_SIZE / 2))
        this.arrowLeft = - (ARROW_PX_SIZE / 2)
        return
      }
      this.arrowTop = - (ARROW_PX_SIZE / 2)
      this.arrowLeft = (left - parseInt(this.$refs.tooltip.calculatedLeft, 10)) + ((width / 2) - (ARROW_PX_SIZE / 2))
    },
  },
}
</script>

<style lang="scss" scoped>
$tooltip-color: var(--v-content-darken4);

.n-tooltip {
  background-color: $tooltip-color;
  padding: map-get($spacers, 1) map-get($spacers, 2);
  font-size: 12px;
  border-radius: 6px;
  max-width: 380px;
  text-align: center;
  word-break: break-word;
  white-space: pre-wrap;

  &__arrow {
    position: absolute;
    background-color: $tooltip-color;
    transform: rotate(45deg);
  }
}
</style>